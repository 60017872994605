.panel-info > .panel-heading
{
    background-color: #5cb460;
    /*background-color: #ebebe0;*/
    margin-bottom: 0px;
}
.silver-header
{
    background-color: #5cb460;
    /*background-color: #ebebe0;*/
    margin-bottom: 0px;
}
.panel-info > .panel-footer
{
    background-color:#3d3d29;
    margin-bottom: 0px;
    margin-top : 0px;
}
.panel-info > .panel-body
{
    margin-bottom: 0px;
    margin-top : 0px;
}
.dark-title
{
    /*color: black;*/
    color : white;
}
.btn{
    white-space: normal;
    margin : 5px;
}